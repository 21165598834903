import axios from "@/request/http"
import base from "@/request/base"
import * as QS from "querystring"

const performance = {
  index(params) {
    return axios.get(`${base.url}/v2/performance/config/list`, {params: params})
  },
  saveAudit(params, status) {
    return axios.post(`${base.url}/v2/performance/config/save/audit`, QS.stringify({
      id: params.id ? params.id : null,
      post_id: params.post_id ? params.post_id : null,
      dept_id: params.dept_id ? params.dept_id : null,
      name: params.name ? params.name : null,
      status: status ? status : '',
      indicator: params.indicator ? params.indicator : 0,
      target_items: params.target_items ? JSON.stringify(params.target_items) : null,
      formula: params.formula ? JSON.stringify(params.formula) : null,
      settings: params.settings ? JSON.stringify(params.settings) : null,
    }))
  },
  getPostAudit(id) {
    return axios.get(`${base.url}/v2/performance/config/post/${id}/audit`)
  },
  getAuditData(id) {
    return axios.get(`${base.url}/admin/performance/audit/${id}/setting`)
  }
}

export default performance
