import api from "@/request/api/auth";
import {ElMessage} from "element-plus";

export default {
  namespace: 'true',
  state() {
    return {
      user: JSON.parse(localStorage.getItem('user') || '{}'),
      token: JSON.parse(localStorage.getItem('token') || '{}'),
      loading: false,
    }
  },
  mutations: {
    async showLoading(state, loading) {
      state.loading = loading
    },
    async changeUserInfo(state, token) {
      state.token = token
      if (undefined === token || !token.access_token) {
        state.user = undefined
      } else {
        await api.getCurrentUser(token.access_token).then(user => {
          if (!user.name) {
            ElMessage.error({
              grouping: true,
              message: '被封用户无法正常登录', duration: 1000, onClose() {
                localStorage.removeItem('token')
                location.reload()
              }
            })
          } else {
            state.user = user
            localStorage.setItem('user', JSON.stringify(user))
          }
        })
      }
    },
  },
  actions: {
    EDIT_USER_TOKEN({commit}, token) {
      return new Promise((res) => {
        commit('changeUserInfo', token || {})
        if (undefined === token) {
          localStorage.removeItem('token')
        } else {
          localStorage.setItem('token', JSON.stringify(token))
        }
        res()
      })
    },
    EDIT_LOADING({commit}, loading) {
      return new Promise((res) => {
        commit('showLoading', loading)
        res()
      })
    },
  },
  getters: {
    isLoading: (state) => {
      return state.loading
    },
    isLogged: (state) => {
      if (state.user && state.user.name && state.token && state.token.access_token) return true
      return false
    },
    tokenInfo: (state) => {
      return state.token
    },
    userInfo: (state) => {
      return state.user
    }
  }
}