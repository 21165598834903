import { createApp } from "vue";
import App from "./App.vue";
import store from "@/base/store/index";
import router from "@/router";
import "@/static/css/main.css";
import api from "@/request/api";

import zhCn from "element-plus/es/locale/lang/zh-cn";
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";

const app = createApp(App);

app.use(router).use(store).use(ElementPlus, { locale: zhCn }).mount("#app");
app.config.globalProperties["$api"] = api;

// 全局按钮权限指令
app.directive("roleBtn", (el) => {
  const role = store.getters.userInfo.role;
  if (role.includes("ROLE_CEO") || role.includes("ROLE_ADMIN_HR")) {
    el.style.display = "inline-block";
  } else {
    el.style.display = "none";
  }
});
