<template>
  <el-container>
    <el-header class="page-header">
      <header-content :show-menu="showMenu">
        <template #collapse>
          <img src="@/assets/icons/common/collapse.svg" alt="collapse" v-if="showMenu" @click="showMenu = false"
            style="width: 16px; height: 16px; cursor: pointer" />
          <img src="@/assets/icons/common/expand.svg" alt="expand" v-else @click="showMenu = true" width="16px"
            style="width: 16px; height: 16px; cursor: pointer" />
        </template>
      </header-content>
    </el-header>
    <el-container :class="'page-content' + ('Index' === $route.name ? ' homepage' : '')">
      <el-aside v-show="showMenu" width="208px" class="page-sidebar">
        <router-sidebar></router-sidebar>
      </el-aside>
      <el-main v-loading="$store.getters.isLoading" style="padding: 0;overflow-x:hidden;">
        <div :class="showMenu ? 'main-breadcrumb-expand' : 'main-breadcrumb-collapse'" v-if="'Index' !== $route.name">
          <render-breadcrumb />
        </div>
        <div :class="'main-content' + (showMenu ? ' show-router-menu' : '')">
          <el-scrollbar class="page-main" :style="'Index' === $route.name ? 'padding-right:0' : ''">
            <el-row v-if="isSubmenu(currentRoute)">
              <el-col :lg="6" :md="12" :sm="24" v-for="route in currentRoute.children" :key="route.path">
                <el-link v-if="hasPermission(route)" @click="$router.push({ name: route.name })" type="primary">{{
                  route.meta.title
                }}</el-link>
              </el-col>
            </el-row>
            <template v-else>
              <el-card v-if="tabs && tabs.length > 0">
                <el-menu mode="horizontal" :default-active="pageTab" class="page-main-tabs">
                  <el-menu-item v-for="item in tabs" :key="item.tab" :index="item.tab" @click="toTabPage(item.tab)">
                    {{ item.title }}
                  </el-menu-item>
                </el-menu>
                <router-view :name="pageTab"></router-view>
              </el-card>
              <router-view v-else :name="pageTab"></router-view>
            </template>
            <slot name="content"></slot>
          </el-scrollbar>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script setup>
import HeaderContent from "@/views/base/HeaderContent"
import { getCurrentInstance, ref, watchEffect, provide } from "vue"
import RouterSidebar from "@/views/base/RouterSidebar"
import RenderBreadcrumb from "@/views/base/RenderBreadcrumb"

const { proxy } = getCurrentInstance()
let showMenu = ref(true)
const currentRoute = ref({})
const tabs = ref([])
const pageTab = ref("default")
provide("showMenu", showMenu)

const getCurrentRoute = () => {
  proxy.$route.matched.forEach((route) => {
    if (proxy.$route.name === route.name) {
      currentRoute.value = route
    }
  })
}

const isSubmenu = (route) => {
  return route.meta && !route.meta.page && route.children && route.children.length > 0
}

const initPageTabs = () => {
  if (!proxy.$store.getters.isLogged) return
  tabs.value = []
  pageTab.value = "default"
  if (proxy.$route.meta && proxy.$route.meta.page) {
    proxy.$route.matched.forEach((menu) => {
      if (proxy.$route.name === menu.name && menu.children && menu.children.length > 0) {
        menu.children.forEach((tab) => {
          if (tab.meta.tab && (proxy.$store.getters.userInfo.nodes.indexOf(tab.path) >= 0 || tab.meta.public)) {
            tabs.value.push(tab.meta)
            if (proxy.$route.params.tab && proxy.$route.params.tab === tab.meta.tab) {
              pageTab.value = tab.meta.tab
            } else if (1 === tabs.value.length) {
              pageTab.value = tabs.value[0].tab
            }
          }
        })
      }
    })
  }
}

const toTabPage = (tab) => {
  if (tab !== pageTab.value) proxy.$router.push({ name: proxy.$route.name, params: { tab: tab } })
}

let hasPermission = (route) => {
  if (!proxy.$store.getters.userInfo.nodes || proxy.$store.getters.userInfo.nodes.indexOf(route.path) < 0) return false

  return true
}

watchEffect(() => {
  getCurrentRoute()
  initPageTabs()
})
</script>

<style>
body {
  margin: 0 !important;
}
</style>
