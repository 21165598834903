<template>
  <el-breadcrumb class="page-breadcrumb" v-if="'Index' !== $route.name">
    <template v-for="route in $route.matched" :key="route.path">
      <el-breadcrumb-item v-if="$route.path !== route.path" @click="goBack(route.path)">{{ route.meta.title }}
      </el-breadcrumb-item>
      <el-breadcrumb-item v-else>{{ route.meta.title }}</el-breadcrumb-item>
    </template>
  </el-breadcrumb>
</template>

<script setup>
import { getCurrentInstance } from "vue";
const { proxy } = getCurrentInstance()

const goBack = (path) => {
  if (path === '/job/list') return proxy.$router.push(path)
  if (path === '/job' && proxy.$route.matched.length > 2) return proxy.$router.push(proxy.$route.matched[1].path)
  window.location.reload()
}
</script>

<style scoped>
:deep(.el-breadcrumb__inner) {
  font-family: PingFangSC-Regular, PingFang SC;
  color: #999999;
  cursor: pointer;
}

:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner) {
  color: #333333;
  font-weight: 500;
  cursor: pointer;
}

:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover) {
  color: #333333;
  font-weight: 500;
  cursor: pointer;
}
</style>