import axios from "@/request/http"
import base from "@/request/base"
import QS from "querystring";

const announcement = {
  announcement(params) {
    return axios.get(`${base.url}/admin/announcement/list`, {params: params})
  },
  saveAnnouncement(params) {
    return axios.post(`${base.url}/admin/announcement/save`, QS.stringify({
      id: params.id ? params.id : null,
      type: params.type ? params.type : null,
      title: params.title ? params.title : null,
      status: params.status ? params.status : null,
      show_time: JSON.stringify(params.show_time && params.show_time[0] && params.show_time[1] ? params.show_time : []),
      content: params.content ? params.content : null,
      published: params.published ? params.published : null,
      seq: params.seq ? params.seq : null
    }))
  },
  changeAnnouncement(id, status) {
    return axios.post(`${base.url}/admin/announcement/status`, QS.stringify({
      id: id, status: status
    }))
  },
}

export default {announcement}
