<template>
  <el-menu mode="horizontal" menu-trigger="click" :ellipsis="false">
    <el-menu-item index="0" @click="$router.push({ name: 'Index' })"
      :class="showMenu ? 'unset-hover system-logo' : 'unset-hover logo-collapse'">
      <el-row>
        <el-space size="small">
          <img src="~assets/logo.svg">
          <span class="title" v-show="showMenu">易诺OA</span>
        </el-space>
      </el-row>
    </el-menu-item>
    <div style="display: flex;align-items: center;">
      <slot name="collapse"></slot>
    </div>
    <div class="flex-grow"></div>
    <el-menu-item v-if="$store.getters.isLogged" index="2" class="unset-hover">
      <div @click="$router.push({ name: 'Notification', params: { tab: 'index' } })">
        <img src="@/assets/icons/common/info.svg" alt="info" style="width:18px; height:60px">
      </div>
    </el-menu-item>

    <el-sub-menu v-if="$store.getters.isLogged" index="3" :title="$store.getters.userInfo.name">
      <template #title>
        <el-space>
          <el-avatar :src="$store.getters.userInfo.avatar"></el-avatar>
          <span>{{ $store.getters.userInfo.name }}</span>
        </el-space>
      </template>
      <el-menu-item v-if="$store.getters.userInfo.name" @click="logout">
        <el-icon>
          <switch-button />
        </el-icon>
        退出登录
      </el-menu-item>
    </el-sub-menu>
  </el-menu>
</template>

<script setup>
import { ElMessage, ElMessageBox } from "element-plus";
import { getCurrentInstance, defineProps } from "vue";
import { SwitchButton } from "@element-plus/icons-vue";

defineProps({ showMenu: { type: Boolean, default: true } })

const { proxy } = getCurrentInstance()
const logout = () => {
  ElMessageBox.confirm(
    '确定退出登录?',
    '退出登录',
    { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }
  ).then(() => {
    proxy.$api.auth.logout(proxy.$store.getters.tokenInfo.access_token).then(res => {
      if ('success' === res.status) {
        ElMessage.success({
          grouping: true,
          message: '成功退出登录', duration: 1000, onClose: () => {
            proxy.$store.dispatch('EDIT_USER_TOKEN', undefined)
            proxy.$router.push({ path: '/login' })
          }
        })
      } else {
        ElMessage.error('退出登录失败')
      }
    })
  }).catch(() => {
  })
}

</script>

<style scoped>
.title {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 400;
  color: #262626;
}

.logo-collapse {
  padding: 0 0 0 20px !important;
  height: 59px;
  line-height: 58px;
}

.logo-collapse img {
  width: 48px;
  height: 48px;
  padding: 6px 0;
  margin-right: 24px;
}
</style>