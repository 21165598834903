<template>
  <el-menu :default-active="$route.path" :router="true">
    <template v-for="menu in routes" :key="menu.name">
      <el-sub-menu v-if="isVisitable(menu) && menu.meta.header" :index="menu.path">
        <template #title>
          {{ menu.meta.title }}
          <span class="dot" v-if="(menu.meta.title === '绩效考核') && ((auditCount !== 0) || (fillCount !== 0))"></span>
        </template>
        <template v-for="submenu in menu.children" :key="submenu.name">
          <el-sub-menu v-if="isVisitable(submenu) && submenu.meta.group" :index="submenu.path">
            <template #title>{{ submenu.meta.title }}</template>
            <template v-for="item in submenu.children" :key="item.name">
              <el-menu-item v-if="isVisitable(item)" :index="item.path">{{ item.meta.title }}</el-menu-item>
            </template>
          </el-sub-menu>
          <el-menu-item v-else-if="isVisitable(submenu) && !submenu.meta.group" :index="submenu.path">
            {{ submenu.meta.title }}
            <span class="audit-badge" v-if="submenu.meta.title === '待填写' && fillCount !== 0">{{ fillCount }}</span>
            <span class="audit-badge" v-if="submenu.meta.title === '待审核' && auditCount !== 0">{{ auditCount }}</span>
          </el-menu-item>
        </template>
      </el-sub-menu>
      <el-menu-item v-else-if="isVisitable(menu) && menu.meta.single_page" :index="menu.path">{{ menu.meta.title }}
      </el-menu-item>
    </template>
  </el-menu>
</template>
<script setup>
import { getCurrentInstance, onUpdated, ref } from "vue"
import api from "@/request/api";

const { proxy } = getCurrentInstance()
const routes = proxy.$router.getRoutes()
const fillCount = ref(0)
const auditCount = ref(0)

const isVisitable = menu => {
  if (!proxy.$store.getters.isLogged) return false

  if (menu.meta.hidden) return false
  if ('Index' === menu.name) return true
  if (menu.meta.public) return true

  if (menu.children && menu.children.length) {
    let result = false
    menu.children.forEach(subMenu => {
      if (isVisitable(subMenu)) result = true
    })
    return result
  }

  if (!proxy.$store.getters.userInfo || !proxy.$store.getters.userInfo.nodes) return false

  if (proxy.$store.getters.userInfo.nodes.indexOf(menu.path) >= 0) return true
  return false
}

onUpdated(() => {
  const current = Math.ceil(new Date().getTime() / 1000)
  if (proxy.$store.getters.isLogged && proxy.$store.getters.tokenInfo.refresh_expire > current && proxy.$store.getters.tokenInfo.access_expire > current) {
    api.performance.result.index({
      simple: 1,
      filter_current_audit: 1
    }).then(res => {
      auditCount.value = res
    })
    api.performance.audit.list(1).then(res => {
      fillCount.value = res
    })
  }
})
</script>
<style scoped>
.audit-badge {
  width: 18px;
  height: 18px;
  background: #F56C6C;
  border-radius: 50%;
  font-size: 12px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 16px;
  margin-left: 5px;
}

.dot {
  width: 8px;
  height: 8px;
  background: #F56C6C;
  border-radius: 50%;
  margin-bottom: 10px;
}
</style>