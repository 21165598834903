import axios from "@/request/http";
import base from "@/request/base";
import QS from "querystring";

const upload = {
  img(params) {
    return axios.post(`${base.url}/upload/image`, params);
  },
  importCheck(target, file) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("target", target);
    return axios.post(`${base.url}/import/check`, formData);
  },
  prepareImport(params) {
    return axios.post(
      `${base.url}/import/prepare/import`,
      QS.stringify(params)
    );
  },
  import(params) {
    return axios.post(`${base.url}/import/import`, QS.stringify(params));
  },
  file(params) {
    return axios.post(`${base.url}/upload/file`, params);
  },
  uploadFile(params) {
    return axios.post(`${base.url}/admin/talent/upload`, params);
  },
  preview(url) {
    return axios.get(`${base.url}/upload/preview`, {params: {url: url}})
  }
};

export default upload;
