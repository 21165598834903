import axios from "@/request/http"
import base from "@/request/base"
import QS from "querystring";

const role = {
  index(params) {
    return axios.get(`${base.url}/admin/role/list`, {params: params})
  },
  unique(params) {
    return axios.get(`${base.url}/admin/role/unique`, {params: params})
  },
  save(params) {
    return axios.post(`${base.url}/admin/role/save`, QS.stringify({
      id: params.id ? params.id : null,
      name: params.name ? params.name : null,
      code: params.code ? params.code : null,
      info: params.info ? params.info : null,
      nodes: params.nodes ? JSON.stringify(params.nodes) : null,
    }))
  },
  delete(id) {
    return axios.get(`${base.url}/admin/role/${id}/delete`)
  },
  all() {
    return axios.get(`${base.url}/admin/role/all`)
  },
}

export default role
