/**
 * admin统一出口
 */
import performance from "@/request/api/admin/performance"
import role from "@/request/api/admin/role";
import system from "@/request/api/admin/system";
import user from "@/request/api/admin/user"

export default {
  performance, role,
  system, user,
}
