import axios from "@/request/http"
import base from "@/request/base"
import QS from "querystring";

const auth = {
  login(params) {
    return axios.post(`${base.url}/auth/login`, QS.stringify(params))
  },
  refresh(token) {
    return axios.post(`${base.url}/auth/refresh/token`, QS.stringify({token: token}))
  },
  logout(token) {
    return axios.post(`${base.url}/auth/logout`, QS.stringify({token: token}))
  },
  getCurrentUser(token) {
    return axios.get(`${base.url}/auth/user/current`, {headers: {Authorization: token}})
  }
}

export default auth
