import axios from "@/request/http"
import base from "@/request/base"
import QS from "querystring"

const department = {
  index() {
    return axios.get(`${base.url}/admin/department/list`)
  },
  users(id) {
    return axios.get(`${base.url}/admin/department/${id}/users`)
  },
  posts(id) {
    return axios.get(`${base.url}/admin/department/${id}/posts`)
  },
  sync() {
    return axios.post(`${base.url}/admin/department/sync`)
  },
}

const post = {
  index(params) {
    return axios.get(`${base.url}/admin/post/list`, {params: params})
  },
  all() {
    return axios.get(`${base.url}/admin/post/all`)
  },
  unique(params) {
    return axios.get(`${base.url}/admin/post/unique`, {params: params})
  },
  users(id) {
    return axios.get(`${base.url}/admin/post/${id}/users`)
  },
  save(params) {
    return axios.post(
      `${base.url}/admin/post/save`,
      QS.stringify({
        id: params.id ? params.id : null,
        name: params.name ? params.name : null,
        users: params.users ? JSON.stringify(params.users) : null,
        leader_id: params.leader_id ? params.leader_id : null,
        audit_user_ids: params.audit_user_ids ? JSON.stringify(params.audit_user_ids) : null,
      })
    )
  },
  delete(id) {
    return axios.post(`${base.url}/admin/post/${id}/delete`)
  },
  getPostDeptLeader(postId) {
    return axios.get(`${base.url}/admin/post/${postId}/dept/leader`)
  },
}

const manager = {
  index(params) {
    return axios.get(`${base.url}/admin/user/list`, {params: params})
  },
  sync() {
    return axios.post(`${base.url}/admin/user/sync`)
  },
  updateRole(id, params) {
    return axios.post(
      `${base.url}/admin/user/${id}/update/role`,
      QS.stringify({
        role: JSON.stringify(params.role ? params.role : []),
      })
    )
  },
  earlyFullTime(userId, params) {
    return axios.post(`${base.url}/v2/user/${userId}/set/full`, QS.stringify({
      regularization_date: params.regularization_date ? params.regularization_date : null,
    }))
  },
  extraLeave(params) {
    return axios.get(`${base.url}/admin/user/extra/leave`, {params: params})
  },
  saveExtraLeave(params) {
    return axios.post(`${base.url}/admin/user/save/extra/leave`, QS.stringify(params))
  },
  deleteLeaveExtra(id) {
    return axios.post(`${base.url}/admin/user/delete/extra/leave`, QS.stringify({id: id}))
  },
  sendLeaveNotice() {
    return axios.get(`${base.url}/admin/user/send/leave/notice`)
  },
}

export default {manager, department, post}
