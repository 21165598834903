import axios from "@/request/http";
import base from "@/request/base";
import QS from "querystring";

const audit = {
  list(simple = 0) {
    return axios.get(`${base.url}/v2/performance/audit/todo/fill`, {
      params: { simple: simple },
    });
  },
  settings(
    id,
    type = undefined,
    user_ids = undefined,
    audit_time_type = "month",
    audit_time = 0
  ) {
    return axios.get(`${base.url}/v2/performance/audit/${id}/settings`, {
      params: {
        type: type,
        user_ids: JSON.stringify(user_ids ? user_ids : []),
        audit_time: audit_time > 0 ? audit_time : "",
        audit_time_type: audit_time_type ? audit_time_type : "",
      },
    });
  },
  saveSettings(params) {
    console.log(params);
    return axios.post(
      `${base.url}/v2/performance/audit/${params.auditId}/user/${params.userId}/save/details`,
      QS.stringify({
        settings: JSON.stringify(params.settings ? params.settings : []),
        audit_time_type: params.audit_time_type ? params.audit_time_type : "",
        audit_time: params.audit_time ? params.audit_time : 0,
      })
    );
  },
  checkAuditEditable(params) {
    return axios.get(`${base.url}/performance/audit/check/editable`, {
      params: {
        audit_id: params.audit_id ? params.audit_id : 0,
        audit_time: params.audit_time ? params.audit_time : 0,
        audit_time_type: params.audit_time_type
          ? params.audit_time_type
          : "month",
        submittable: params.submittable ? params.submittable : 0,
      },
    });
  },
  getAuditDetail(params) {
    return axios.get(
      `${base.url}/v2/performance/audit/${params.audit_id}/user/${params.user_id}/details`,
      {
        params: {
          audit_time: params.audit_time ? params.audit_time : 0,
          audit_time_type: params.audit_time_type
            ? params.audit_time_type
            : "month",
        },
      }
    );
  },
  // 提交/审核/驳回单个考核表
  submitSingle(params) {
    return axios.post(
      `${base.url}/v2/performance/audit/${params.audit_id}/user/${params.user_id}/update/results`,
      QS.stringify({
        audit_time: params.audit_time ? params.audit_time : 0,
        audit_time_type: params.audit_time_type
          ? params.audit_time_type
          : "month",
        status: params.status ? params.status : "",
        remark: params.remark ? params.remark : "",
      })
    );
  },
  // 批量提交/审核/驳回考核表
  submitMultiple(params) {
    return axios.post(
      `${base.url}/v2/performance/audit/${params.audit_id}/batch/update/results`,
      QS.stringify({
        user_ids: JSON.stringify(params.user_ids ? params.user_ids : []),
        audit_time: params.audit_time ? params.audit_time : 0,
        audit_time_type: params.audit_time_type
          ? params.audit_time_type
          : "month",
        status: params.status ? params.status : "",
        remark: params.remark ? params.remark : "",
      })
    );
  },
};

const target = {
  fillList(simple = 1, finished = 0) {
    return axios.get(`${base.url}/performance/target/fill/list`, {
      params: { simple: simple, finished: finished },
    });
  },
  auditList(simple = 1, finished = 0) {
    return axios.get(`${base.url}/performance/target/audit/list`, {
      params: { simple: simple, finished: finished },
    });
  },
  detailList(simple = 1) {
    return axios.get(`${base.url}/performance/target/detail/list`, {
      params: { simple: simple },
    });
  },
  saveTargets(targets) {
    return axios.post(
      `${base.url}/performance/target/save`,
      QS.stringify({
        targets: JSON.stringify(targets),
      })
    );
  },
  auditTargets(params) {
    return axios.post(
      `${base.url}/performance/target/change/status`,
      QS.stringify({
        rejects: JSON.stringify(params.rejects),
        accepts: JSON.stringify(params.accepts),
      })
    );
  },
  targetProgressMonth() {
    return axios.get(`${base.url}/performance/target/progress/month`);
  },
  progressRankList() {
    return axios.get(`${base.url}/performance/target/progress/rank/list`);
  },
  progressRankDetail(params) {
    return axios.get(`${base.url}/performance/target/progress/rank/detail`, {
      params: {
        dept_id: params.dept_id ? params.dept_id : 0,
        target_item: params.target_item ? params.target_item : "",
        personal: params.personal ? 1 : 0,
      },
    });
  },
};

const result = {
  index(params) {
    return axios.get(`${base.url}/v2/performance/audit/todo/submit`, {
      params: params,
    });
  },
  // 获取考核表考核结果
  getAuditResult(params, id) {
    return axios.get(`${base.url}/v2/performance/audit/${id}/user/results`, {
      params: params,
    });
  },
  updateStatus(params) {
    return axios.post(
      `${base.url}/v2/performance/audit/${params.audit_id}/batch/update/results`,
      QS.stringify({
        audit_time: params.audit_time ? params.audit_time : 0,
        audit_time_type: params.audit_time_type
          ? params.audit_time_type
          : "month",
        status: params.status ? params.status : "",
        remark: params.remark ? params.remark : "",
        user_ids: JSON.stringify(params.user_ids ? params.user_ids : []),
      })
    );
  },
  season(params) {
    return axios.get(`${base.url}/v2/performance/audit/result/list`, {
      params: params,
    });
  },
  userResult(params) {
    return axios.get(`${base.url}/performance/result/user/list`, {
      params: params,
    });
  },
  checkRole() {
    return axios.get(`${base.url}/performance/result/check/role`);
  },
  checkGrade() {
    return axios.get(`${base.url}/performance/result/check/grade`);
  },
  history(params) {
    return axios.get(`${base.url}/performance/result/history`, {
      params: params,
    });
  },
  downloadDetail(id) {
    return axios.post(`${base.url}/performance/result/detail/download/${id}`);
  },
  downloadSelectionDetail(params) {
    return axios.post(
      `${base.url}/v2/performance/audit/result/batch/download/detail`,
      QS.stringify(params)
    );
  },
};

const indicator = {
  get(params) {
    return axios.get(`${base.url}/performance/indicator/get`, {
      params: {
        type: params.type ? params.type : null,
        year: params.year ? params.year : null,
        season: params.season ? params.season : null,
        month: params.month ? params.month : null,
      },
    });
  },
  list(params) {
    return axios.get(`${base.url}/performance/indicator/list`, {
      params: params,
    });
  },
  save(params) {
    return axios.post(
      `${base.url}/performance/indicator/save`,
      QS.stringify({
        type: params.type ? params.type : null,
        year: params.year ? params.year : null,
        season: params.season ? params.season : null,
        month: params.month ? params.month : null,
        value: params.value ? params.value : 0,
        status: params.status ? params.status : "",
        is_public: params.is_public ? params.is_public : 2,
      })
    );
  },
  batchAudit(params) {
    return axios.post(
      `${base.url}/performance/indicator/batch/audit`,
      QS.stringify({
        ids: JSON.stringify(params.ids ? params.ids : []),
        status: params.status ? params.status : "",
      })
    );
  },
};

export default { audit, target, result, indicator };
