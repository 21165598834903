const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/views/home/Index"),
    meta: {title: "首页", single_page: true, page: true},
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/auth/Login"),
    meta: {title: "登录", public: true, hidden: true},
  },
  {
    path: "/login/callback",
    name: "LoginCallback",
    component: () => import("@/views/auth/Login"),
    meta: {title: "登录回调", public: true, hidden: true},
  },
  {
    path: "/login/refresh",
    name: "LoginRefresh",
    component: () => import("@/views/auth/Login"),
    meta: {title: "登录", public: true, hidden: true},
  },
  {
    path: "/notification/:tab",
    name: "Notification",
    meta: {
      title: "通知",
      hidden: true,
      single_page: true,
      page: true,
      public: true,
    },
    components: {
      index: () => import("@/views/notification/Notifications"),
      announcement: () => import("@/views/notification/Announcements"),
    },
    children: [
      {
        path: "notification/index",
        meta: {title: "通知", tab: "index", hidden: true, public: true},
      },
      {
        path: "notification/announcement",
        meta: {
          title: "公告",
          tab: "announcement",
          hidden: true,
          public: true,
        },
      },
    ],
  },
  {
    path: "/performance",
    name: "Performance",
    component: () => import("@/views/base/RenderView"),
    meta: {header: true, title: "绩效考核"},
    children: [
      {
        path: "/performance/index",
        name: "PerformanceIndex",
        component: () => import("@/views/performance/Index"),
        meta: {title: "待填写"},
      },
      {
        path: "/performance/deal",
        name: "PerformanceDeal",
        component: () => import("@/views/performance/Deal"),
        meta: {title: "待审核"},
      },
      {
        path: "/performance/result",
        name: "PerformanceResult",
        component: () => import("@/views/performance/result/Index"),
        meta: {title: "考核结果"},
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("@/views/base/RenderView"),
    meta: {header: true, title: "Admin"},
    children: [
      {
        path: "/admin/user",
        name: "AdminUser",
        component: () => import("@/views/base/RenderView"),
        meta: {group: true, title: "用户及部门岗位"},
        children: [
          {
            path: "/admin/user/manager",
            name: "AdminUserManager",
            component: () => import("@/views/admin/user/manager/Index"),
            meta: {title: "用户管理"},
          },
          {
            path: "/admin/user/department",
            name: "AdminUserDepartment",
            component: () => import("@/views/admin/user/department/Index"),
            meta: {title: "部门管理"},
            children: [],
          },
          {
            path: "/admin/user/post",
            name: "AdminUserPost",
            component: () => import("@/views/admin/user/post/Index"),
            meta: {title: "岗位管理"},
            children: [],
          },
        ],
      },
      {
        path: "/admin/performance",
        name: "AdminPerformance",
        component: () => import("@/views/base/RenderView"),
        meta: {group: true, title: "绩效考核设置"},
        children: [
          {
            path: "/admin/performance/audit",
            name: "AdminPerformanceAudit",
            component: () => import("@/views/admin/performance/audit/Index"),
            meta: {title: "考核表设置"},
          },
        ],
      },
      {
        path: "/admin/system",
        name: "AdminSystem",
        component: () => import("@/views/base/RenderView"),
        meta: {group: true, title: "系统设置"},
        children: [
          {
            path: "/admin/system/announcement",
            name: "AdminSystemAnnouncement",
            component: () => import("@/views/admin/system/announcement/Index"),
            meta: {title: "公告设置"},
          },
        ],
      },
      {
        path: "/admin/role",
        name: "AdminRole",
        component: () => import("@/views/admin/role/Index"),
        meta: {title: "角色权限设置"},
      },
    ],
  },
  {
    path: "/job",
    name: "Job",
    component: () => import("@/views/base/RenderView"),
    meta: {header: true, title: "招聘管理"},
    children: [
      {
        path: "/job/workspace",
        name: "JobWorkspace",
        component: () => import("@/views/job/workspace/Index"),
        meta: {title: "招聘数据"},
      },
      {
        path: "/job/list",
        name: "JobLiST",
        component: () => import("@/views/job/resume/List"),
        meta: {title: "简历列表", page: true},
        children: [
          {
            path: "/job/edit/:id",
            name: "JobEdit",
            component: () => import("@/views/job/resume/EditResume"),
            meta: {title: "简历编辑", operation: true},
          },
          {
            path: "/job/info/:id",
            name: "JobInfo",
            component: () => import("@/views/job/resume/Info"),
            meta: {title: "简历详情", operation: true},
          },
        ],
      },
      {
        path: "/job/offer/module",
        name: "JobOfferModule",
        component: () => import("@/views/job/offer/Index"),
        meta: {title: "邮件模板"},
      },
      {
        path: "/job/setting",
        name: "JobSetting",
        component: () => import("@/views/job/Setting"),
        meta: {title: "字段设置"},
      },
    ],
  },
  {
    path: "/develop",
    name: "Develop",
    component: () => import("@/views/base/RenderView"),
    meta: {header: true, title: "Develop", hidden: true},
    children: [
      {
        path: "/develop/setting",
        name: "DevelopSetting",
        component: () => import("@/views/develop/Setting"),
        meta: {title: "Develop Setting", hidden: true},
      },
    ],
  },
];

export default routes;
