import {createRouter, createWebHistory} from 'vue-router'
import routes from "@/router/routes";
import store from "@/base/store";
import {ElMessage} from "element-plus";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes, base: '/'
})


router.beforeEach((to, from, next) => {
  if (to.meta.title) document.title = to.meta.title
  if (store.getters.isLogged) {
    // 已登录
    if (['Login', 'LoginCallback', 'LoginRefresh'].indexOf(to.name) < 0 && store.getters.tokenInfo.access_expire < Math.ceil(new Date().getTime() / 1000)) {
      if (store.getters.tokenInfo.refresh_expire < Math.ceil(new Date().getTime() / 1000)) {
        // token刷新时间已过期
        return next({name: 'Login', query: {goto: to.path}})
      } else {
        //  token快过期了 但是还能刷新
        return next({name: 'LoginRefresh', query: {goto: to.path}})
      }
    } else {
      if (['Login', 'LoginCallback', 'LoginRefresh'].indexOf(to.name) < 0  && to.path != '/' && !to.meta.header && !to.meta.group && !to.meta.public && (!store.getters.userInfo.nodes || store.getters.userInfo.nodes.indexOf(to.path) < 0)) {
        ElMessage.error({
          message: '暂无权限访问', duration: 1000, onClose() {
            return next({path: '/'})
          }
        })
      } else {
        return next()
      }
    }
  } else {
    //  未登录判断
    if (!to.meta.public && 'Login' !== to.name && 'LoginCallback' !== to.name) {
      return next({name: 'Login', query: {goto: from.path}})
    } else {
      return next()
    }
  }
})

export default router
